import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { BrowserRouter, Link, NavLink, } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import { useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import MetaTags from './components/MetaTags';
import WhatIsThisPage from './pages/WhatIsThisPage';
import ArtGallery from './pages/ArtGallery';
import { useDispatch, useSelector } from 'react-redux';
import { getBot, getBotForumStat, getFilenames, getUserForumStat, getUsers } from './actions/allDataActions';





function App() {
  // const [user, setUser] = useState({})
  // const [userForumStat, setUserForumStat] = useState({})
  // const [bot, setBot] = useState({})
  // const [botForumStat, setBotForumStat] = useState({})
  // const [filenames, setFilenames] = useState({})
  // let user = {}
  // let userForumStat = {}
  // let bot = {}
  // let botForumStat = {}
  // let filenames = []
  // const [data, setData] = useState({})
  // const fetchData = async() => {
  //   if(process.env.REACT_APP_CDN_MODE==="local" && localStorage.getItem("data") === null){
  //     user = `${process.env.REACT_APP_STATIC_CDN}/json/user.json`
  //     userForumStat = `${process.env.REACT_APP_STATIC_CDN}/json/userForumStat.json`
  //     bot = `${process.env.REACT_APP_STATIC_CDN}/json/bot.json`
  //     botForumStat = `${process.env.REACT_APP_STATIC_CDN}/json/botForumStat.json`
  //     filenames =`${process.env.REACT_APP_STATIC_CDN}/json/filenames.json`
  //   }else{
  //     const userData = await fetch(`${process.env.REACT_APP_STATIC_CDN}/json/user.json`,{
  //       method: 'GET',
  //       mode: 'cors',
  //     }).then(response => response.json())
  //     .then(data => {
  //       console.log(data)
  //       return data
  //     })
  //     user = userData
  
  //     const userForumStatData = await fetch(`${process.env.REACT_APP_STATIC_CDN}/json/userForumStat.json`,{
  //       method: 'GET',
  //       mode: 'cors',
  //     }).then(response => response.json())
  //     .then(data => {
  //       console.log(data)
  //       return data
  //     })
  //     userForumStat = userForumStatData
  
  //     const botData = await fetch(`${process.env.REACT_APP_STATIC_CDN}/json/bot.json`,{
  //       method: 'GET',
  //       mode: 'cors',
  //     }).then(response => response.json())
  //     .then(data => {
  //       console.log(data)
  //       return data
  //     })
  //     bot = botData
  
  //     const botForumStatData = await fetch(`${process.env.REACT_APP_STATIC_CDN}/json/botForumStat.json`,{
  //       method: 'GET',
  //       mode: 'cors',
  //     }).then(response => response.json())
  //     .then(data => {
  //       console.log(data)
  //       return data
  //     })
  //     botForumStat = botForumStatData
  
  //     const filenamesData = await fetch(`${process.env.REACT_APP_STATIC_CDN}/json/filenames.json`,{
  //       method: 'GET',
  //       mode: 'cors',
  //     }).then(response => response.json())
  //     .then(data => {
  //       console.log(data)
  //       return data
  //     })
  //     filenames = filenamesData


  //     localStorage.setItem("data", JSON.stringify({user, userForumStat, bot, botForumStat, filenames}))


  //     // Retrieve data
  //     setData(JSON.parse(JSON.stringify(localStorage.getItem("data"))));
  //   }
  // }

  const dispatch = useDispatch()

  const userData = useSelector(state => state.userData);
  const {loading: loadingUser, error: errorUser, user} = userData;

  const userForumStatData = useSelector(state => state.userForumStatData);
  const {loading: loadingUserForumStat, error: errorUserForumStat, userForumStat} = userForumStatData;

  const botData = useSelector(state => state.botData);
  const {loading: loadingBot, error: errorBot, bot} = botData;

  const botForumStatData = useSelector(state => state.botForumStatData);
  const {loading: loadingBotForumStat, error: errorBotForumStat, botForumStat} = botForumStatData;

  const filenamesData = useSelector(state => state.filenamesData);
  const {loading: loadingFilenames, error: errorFilenames, filenames} = filenamesData;




  const [menu, setMenu] = useState(false);
  const [musicPlayer, setMusicPlayer] = useState({height: "0", width: "500"})

  const getYouTubeLinkId = (url) =>{
    if(url){
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    }else{
      return ""
    }
    
}
 
  // let audioBeep = new Audio("/assets/beep.mp3");
  // let audioMenuSelectionClick = new Audio("/assets/MenuSelectionClick.mp3");

  const getAllData = () => {
    dispatch(getUsers());
    dispatch(getUserForumStat());
    dispatch(getBot());
    dispatch(getBotForumStat());
    dispatch(getFilenames());
  }
  useEffect(()=>{
    getAllData()
  }, []);

  return (
      <BrowserRouter>
          
        {/* {filenames.map((filename, i)=>(
          <div className='stickyBookmark' style={{height: `${100*i}rem`}}>{filename.albumTitle}</div>
        ))} */}
          
        <MetaTags></MetaTags>
        <div className="grid-containter">
              <header className="row navigation-bar">
                  <div>
                    <NavLink to="/" className={({ isActive }) => (isActive ? 'brand selected' : 'brand')}>Devpresso</NavLink>
                  </div>
                {
                isBrowser ? <>
                  {/* <div>
                    <Link to={`/news`} className="">News</Link>
                  </div> */}
                  {user && <div>
                    <div className='col-0'><div className='clickable itemLink no-select' onClick={()=>{
                      const globalBackgroundMusicPlayer = document.querySelector(".globalBackgroundMusicPlayer");
                      if(globalBackgroundMusicPlayer.classList.contains("invisible")){
                        setMusicPlayer({height: "200", width: "500"})
                      }else{
                        setMusicPlayer({height: "0", width: "500"})
                      }
                      globalBackgroundMusicPlayer.classList.toggle("invisible");
                      
                    }}>Global background music</div>
                     <div className='globalBackgroundMusicPlayer row center invisible' style={{paddingTop: "0"}}>
                        <div className='row center'>
                          <iframe src={`https://www.youtube.com/embed/${getYouTubeLinkId(user.backgroundMusic)}?mute=0&loop=1`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={true} width={musicPlayer.height} height={musicPlayer.height} allow='autoplay'></iframe>
                        </div>
                        <div className='row center'>
                          <iframe style={{position: "relative"}} src={`https://www.youtube.com/embed/videoseries?mute=0&loop=1&list=PLyTTovvxYVsO1UZajvQlvRQSrX61Q6tT3`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={true} width={musicPlayer.height} height={musicPlayer.height}></iframe>
                        </div>
                      </div>
                    </div>
                  </div>}
                  
                  <div>
                    <NavLink className={({ isActive }) => (isActive ? 'itemLink selected' : 'itemLink')} to={`/art-gallery`}>My art gallery</NavLink>
                  </div>
                  <div>
                    <NavLink className={({ isActive }) => (isActive ? 'itemLink selected' : 'itemLink')} to={`/what-is-this`}>What is this?</NavLink>
                  </div>
                      
                  </>: 
                  isMobile &&
                      <div className='row left'>
                        <div className='interactiveText row left'  onClick={()=>
                          {
                            if(!menu){
                              setMenu(!menu);
                            }else{
                              const navigationBar = document.querySelector(".navigationBar");
                                if(navigationBar){
                                  navigationBar.classList.add("close");
                                }
                              setTimeout(()=>{
                                setMenu(!menu);
                              }, 500)
                            }
                          }
                        }><i className={menu ? `fa-solid fa-xmark` : `fa fa-bars`}></i></div>
                        {menu && (<ul className='navigationBar'>
                          <li>
                            <NavLink className={({ isActive }) => (isActive ? 'itemLink selected' : 'itemLink')} to={`/art-gallery`}>My art gallery</NavLink>
                          </li>
                          <li>
                            <NavLink className={({ isActive }) => (isActive ? 'itemLink selected' : 'itemLink')} to={`/what-is-this`}>What is this?</NavLink>
                          </li>
                          <li className='s'>
                            <div className='clickable itemLink no-select' onClick={()=>{
                              const globalBackgroundMusicPlayer = document.querySelector(".globalBackgroundMusicPlayer");
                              if(globalBackgroundMusicPlayer.classList.contains("invisible")){
                                setMusicPlayer({height: "200", width: "500"})
                              }else{
                                setMusicPlayer({height: "0", width: "500"})
                              }
                              globalBackgroundMusicPlayer.classList.toggle("invisible");
                              
                            }}>Global background music</div>
                            <div className='globalBackgroundMusicPlayer row center invisible' style={{paddingTop: "0"}}>
                                <div className='row center'>
                                  <iframe src={`https://www.youtube.com/embed/${getYouTubeLinkId(user.backgroundMusic)}?mute=0&loop=1`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={true} width={musicPlayer.height} height={musicPlayer.height} allow='autoplay'></iframe>
                                </div>
                                <div className='row center'>
                                  <iframe style={{position: "relative"}} src={`https://www.youtube.com/embed/videoseries?mute=0&loop=1&list=PLyTTovvxYVsO1UZajvQlvRQSrX61Q6tT3`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={true} width={musicPlayer.height} height={musicPlayer.height}></iframe>
                                </div>
                              </div>
                          </li>
                        </ul>)
                      }
                      </div>
                  }
              </header>
              <main>
              <div>
              </div>
              {/* {isBrowser} */}
                <Routes>
                  {/* React Router Dom v6 syntax */}
                  <Route exact path="/" element={<HomePage user={user}></HomePage>}></Route>
                  <Route exact path="/what-is-this" element={<WhatIsThisPage></WhatIsThisPage>}></Route>
                  {/* <Route exact path="/signin" element={<SigninPage></SigninPage>}></Route> */}
                  {/* <Route exact path="/signup" element={<SignUpPage></SignUpPage>}></Route> */}
                  <Route path="/user/LhMinh2607" element={<ProfilePage user={user} userForumStat={userForumStat} bot={bot} filenames={filenames}></ProfilePage>}></Route>
                  <Route path="/@LhMinh2607" element={<ProfilePage user={user} userForumStat={userForumStat} bot={bot} filenames={filenames}></ProfilePage>}></Route>
                  <Route path="/LhMinh2607" element={<ProfilePage user={user} userForumStat={userForumStat} bot={bot} filenames={filenames}></ProfilePage>}></Route>
                  <Route path="/me" element={<ProfilePage user={user} userForumStat={userForumStat} bot={bot} filenames={filenames}></ProfilePage>}></Route>
                  <Route path="/@me" element={<ProfilePage user={user} userForumStat={userForumStat} bot={bot} filenames={filenames}></ProfilePage>}></Route>
                  <Route path="/user/MentalBot" element={<ProfilePage user={bot} userForumStat={botForumStat} filenames={filenames}></ProfilePage>}></Route>
                  <Route path="/@MentalBot" element={<ProfilePage user={bot} userForumStat={botForumStat} filenames={filenames}></ProfilePage>}></Route>
                  <Route path="/MentalBot" element={<ProfilePage user={bot} userForumStat={botForumStat} filenames={filenames}></ProfilePage>}></Route>
                  {/* <Route exact path="/forum" element={<ForumPage></ForumPage>}></Route>  */}
                  <Route path="art-gallery" element={<ArtGallery filenames={filenames} user={user}></ArtGallery>}></Route>
                  {/* <Route exact path="/mentalbot" element={<MentalBotPage></MentalBotPage>}></Route>  */}
                  {/* <Route exact path="/forum/post/:id" element={<PostDetailPage></PostDetailPage>}></Route> */}
                  {/* <Route exact path="/feedback" element={<AdminRoute><FeedbackPage></FeedbackPage></AdminRoute>}></Route>  */}
                  {/* <Route exact path="/user/list" element={<AdminRoute><UserListPage></UserListPage></AdminRoute>}></Route>  */}
                  {/* <Route exact path="/admin/stat" element={<AdminRoute><StatisticPage></StatisticPage></AdminRoute>}></Route>  */}
                  {/* <Route exact path="/forum" element={<ForumPage></ForumPage>}></Route> */}
                  {/* {currentSocket !== null && <Route exact path="/forum/post/:id" element={<PostDetailPage currentSocket={currentSocket}></PostDetailPage>}></Route>} */}
                  {/* <Route exact path="/news" element={<NewsPage></NewsPage>}></Route> */}
                  {/* <Route exact path="/chatbot" element={<MobileChatBot></MobileChatBot>}></Route> */}
                  {/* {currentSocket !== null &&<Route exact path="/notification" element={<MobileNotification currentSocket={currentSocket}></MobileNotification>}></Route>} */}
                  {/* <Route exact path="/voicerecorder" element={<VoiceRecorderComponent source="https://webrtc.github.io/samples/src/video/chrome.webm"></VoiceRecorderComponent>}></Route> Easter Eggs */}
                  {/* <Route path="/mobilePhone" element={<div className='row center top'><Keypad/></div>} />  */}
                  <Route path="*" element={<NotFoundPage/>} /> 
                </Routes>
                  
                
              </main>
              <footer>
                  {user && user.role!=="bot" && <div className='row space'>
                        {/* <div className='navTab footer'> */}
                            <div className="interactiveText">
                                <Link className='interactiveText bold' to={user.instagramURL} target='_blank'><i className='fab fa-instagram bold'></i>{isBrowser && user.instagram}</Link>
                            </div>
                            <div className="interactiveText">
                                <Link className='interactiveText bold' to={user.discordURL}  target='_blank'><i className='fab fa-discord bold'></i>{isBrowser && user.discord}</Link>
                            </div>
                            <div className="interactiveText">
                              <Link className='interactiveText bold' to={user.youtubeURL}  target='_blank'><i className='fab fa-youtube bold'></i>{isBrowser && user.youtube}</Link>
                            </div>
                            <div className="interactiveText">
                              <Link className='interactiveText bold' to={user.githubURL}  target='_blank'><i className='fab fa-github bold'></i>{isBrowser && user.github}</Link>
                            </div>
                        {/* </div> */}
                  </div>}
              </footer>
             
          </div>
      </BrowserRouter>
    
  );
}


export default App;
