export const GET_ALL_DATA_REQUEST = 'GET_ALL_DATA_REQUEST';
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_DATA_SUCCESS';
export const GET_ALL_DATA_FAIL = 'GET_ALL_DATA_FAIL';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

export const GET_ALL_USER_FORUM_STATS_REQUEST = 'GET_ALL_USER_FORUM_STATS_REQUEST';
export const GET_ALL_USERS_FORUM_STATS_SUCCESS = 'GET_ALL_USERS_FORUM_STATS_SUCCESS';
export const GET_ALL_USERS_FORUM_STATS_FAIL = 'GET_ALL_USERS_FORUM_STATS_FAIL';

export const GET_BOT_REQUEST = 'GET_BOT_REQUEST';
export const GET_BOT_SUCCESS = 'GET_BOT_SUCCESS';
export const GET_BOT_FAIL = 'GET_BOT_FAIL';

export const GET_ALL_BOT_FORUM_STATS_REQUEST = 'GET_ALL_BOT_FORUM_STATS_REQUEST';
export const GET_ALL_BOT_FORUM_STATS_SUCCESS = 'GET_ALL_BOT_FORUM_STATS_SUCCESS';
export const GET_ALL_BOT_FORUM_STATS_FAIL = 'GET_ALL_BOT_FORUM_STATS_FAIL';

export const GET_FILENAMES_REQUEST = 'GET_FILENAMES_REQUEST';
export const GET_FILENAMES_SUCCESS = 'GET_FILENAMES_SUCCESS';
export const GET_FILENAMES_FAIL = 'GET_FILENAMES_FAIL';