import React from 'react'
import {Link} from 'react-router-dom';


export default function WhatIsThisPage() {
    return (
        <div style={{paddingTop: "5rem"}}>
            
            <div className="row center">
                    {/* <img className="" src="https://thanhbinhpc.com/wp-content/uploads/2019/03/sua-loi-404-tren-may-tinh.1PNG.png"></img> */}
                <div title='COMING SOON' className='glitch-div' style={{fontSize: '10vw'}}>
                    COMING SOON
                </div>
            </div>
            <div className="row center">
                 <Link to='/'>Back to Homepage</Link>   
            </div>
        </div>
    )
}
