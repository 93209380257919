import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import {thunk} from 'redux-thunk';
import { botForumStatReducer, botReducer, filenamesReducer, userForumStatReducer, userReducer } from './reducers/allData';

const initialState = {
    // userSignin: {
    //     userInfo: localStorage.getItem('userInfo')
    //     ? JSON.parse(localStorage.getItem('userInfo'))
    //     : null,
    // },
    // postDetails: {
    //     post: localStorage.getItem('userLSP')
    //     ? JSON.parse(localStorage.getItem('userLSP'))
    //     : "nothing",
    // }
    
    // cart:{
    //     cartItems: localStorage.getItem('cartItems')? JSON.parse(localStorage.getItem('cartItems')) : [],    
    //     paymentMethod: 'Cash',
    //     shippingAddress: localStorage.getItem('shippingAddress'),
    // }
    userData: {loading: false, error: false, user: JSON.parse(localStorage.getItem('user'))},
    userForumStatData: {loading: false, error: false, userForumStat: JSON.parse(localStorage.getItem('userForumStat'))},
    botData: {loading: false, error: false, bot: JSON.parse(localStorage.getItem('bot'))},
    botForumStatData: {loading: false, error: false, botForumStat: JSON.parse(localStorage.getItem('botForumStat'))},
    filenamesData: {loading: false, error: false, filenames: JSON.parse(localStorage.getItem('filenames'))},
};

const reducer = combineReducers({
    userData: userReducer,
    userForumStatData: userForumStatReducer,
    botData: botReducer,
    botForumStatData: botForumStatReducer,
    filenamesData: filenamesReducer,
})


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
    reducer, 
    initialState, 
    composeEnhancer(applyMiddleware(thunk)));

export default store;
