import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
    
function importAll(r) {
    return r.keys().map(r);
}
  
export default function ImageList(props) {
    const {filenames} = props
    const [zoomin, setZoomin] = useState("")
    let albumImages = []
    let images = []
    if(process.env.REACT_APP_IMAGE_LIST_LOCAL){
        const filenames = importAll(require.context(process.env.REACT_APP_IMAGE_LIST_LOCAL, false, /\.(png|jpe?g|svg)$/));
    }else if(process.env.REACT_APP_IMAGE_LIST_CLOUD){
        console.log(filenames)
        albumImages = filenames.map(filename=>(filename.images))
        console.log(albumImages)
        images = albumImages.flat(1)
        images = images.map(image=>process.env.REACT_APP_IMAGE_LIST_CLOUD+"/"+image)
        console.log(images)
    }
    useEffect(()=>{
    }, [zoomin])
    return (
        <div className='gallery'>
            <div className='row center' style={{paddingBottom: "1rem", padding: "1rem"}}>
                <button className=''>
                    <Link to={`/art-gallery`}>View these in my ary gallery (recommended)</Link>
                </button>
            </div>
        
        {
            images.map(image => {
            return <div className='row center interactiveText' onClick={()=>setZoomin(image)} style={{paddingBottom: "1rem"}}>
                <img className='showcase' src={image} alt={image}/>
            </div>
            })
        }
        {zoomin && <div className='viewingImageDiv' onClick={()=>setZoomin("")}><img className='zoomimg' src={`${zoomin}`}></img> <div className='' style={{backgroundColor: "#000"}}>click again to close</div></div>}
        </div>
    )
}
  