import React, { useEffect, useState } from 'react'
import UserMiniProfile from '../components/UserMiniProfile';
import YouTubeVideoPlayer from '../components/YouTubeVideoPlayer';

export default function ArtGallery(props) {
    const [zoomin, setZoomin] = useState("")
    const {filenames, user} = props
    const path = process.env.REACT_APP_STATIC_CDN_ASSETS_SHOWCASE
    useEffect(()=>{
        const elements = document.querySelectorAll(".stickyBookmark")
        const observer = new IntersectionObserver( 
          ([e]) => e.target.classList.toggle("sticked", e.intersectionRatio < 1),
          { threshold: [1] }
        );
        Array.from(elements).forEach((el)=>
        {
            observer.observe(el)
        });
    }, [zoomin])
    return (
        <div style={{overflow: "auto"}}>
            <div className='scrollableDiv full'>
                <div className='row center' id="note">
                    <div className='col-0'>
                        <div className='row center'>
                            <div><UserMiniProfile user={user} placebotext={`Art by `}></UserMiniProfile></div>
                        </div>
                    </div>
                </div>
                {/* {
                    filenames.map(filename => 
                        (<div id={filename.albumTitle} className='col-0 tabItem album'>
                            <div>
                                <h1 className='albumTitle' title={filename.albumTitle} style={{fontSize: '10vw'}}>
                                    {filename.albumTitle}
                                </h1>
                                {filename.images.map((f)=><div className='row center interactiveText' onClick={()=>setZoomin(f)} style={{paddingBottom: "1rem"}}>
                                <img className='showcase' src={"/assets/showcase/"+f} alt={f}/>
                            </div>)}</div>
                        </div>
                    ))
                } */}
                {
                    filenames && filenames.length > 0 && filenames.map(filename => 
                        (<div id={filename.albumTitle} className='col-0 tabItem album'>
                                <h1 className='albumTitle stickyBookmark' title={filename.albumTitle}>
                                    {filename.albumTitle}
                                </h1>
                                {filename.images.map((f)=><div className='row center interactiveText' onClick={()=>setZoomin(f)} style={{paddingBottom: "1rem"}}>
                                <img className='showcase' src={`${path}/${f}`} alt={f}/>
                            </div>)}
                                {filename?.videos && filename?.videos.map((f)=><div className='row center interactiveText' onClick={()=>setZoomin(f)} style={{paddingBottom: "1rem"}}>
                                <div className='col-0'>
                                    <YouTubeVideoPlayer videoId={f} height="500" width="500" autoplay={true} allowFullScreen={true} loop={true} mute={true}></YouTubeVideoPlayer>
                                </div>  
                                </div>)}
                        </div>
                    ))
                }
                <div className='row center' id="note" style={{paddingBottom: "5rem"}}>
                    <div className='col-0'>
                        <div className='row center'>Took me a bit too long to get these sticky titles to work properly 💀</div>
                    </div>
                   
                </div>
                {zoomin && <div className='viewingImageDiv' onClick={()=>setZoomin("")}><img className='zoomimg' src={`${path}/${zoomin}`}></img> <div className='' style={{backgroundColor: "#000"}}>click again to close</div></div>}
                
            </div>
        </div>
    ) 
}
