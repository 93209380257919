import { GET_ALL_BOT_FORUM_STATS_FAIL, GET_ALL_BOT_FORUM_STATS_REQUEST, GET_ALL_BOT_FORUM_STATS_SUCCESS, GET_ALL_DATA_FAIL, GET_ALL_DATA_REQUEST, GET_ALL_DATA_SUCCESS, GET_ALL_USER_FORUM_STATS_REQUEST, GET_ALL_USERS_FAIL, GET_ALL_USERS_FORUM_STATS_FAIL, GET_ALL_USERS_FORUM_STATS_SUCCESS, GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_BOT_FAIL, GET_BOT_REQUEST, GET_BOT_SUCCESS, GET_FILENAMES_FAIL, GET_FILENAMES_REQUEST, GET_FILENAMES_SUCCESS } from "../constants/allData";


export const userReducer = (state = {loading: true, stat: {}}, action) =>{
    switch(action.type){
        case GET_ALL_USERS_REQUEST: 
            return {loading: true};
        case GET_ALL_USERS_SUCCESS:
            return {loading: false, user: action.payload};
        case GET_ALL_USERS_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const userForumStatReducer = (state = {loading: true, stat: {}}, action) =>{
    switch(action.type){
        case GET_ALL_USER_FORUM_STATS_REQUEST: 
            return {loading: true};
        case GET_ALL_USERS_FORUM_STATS_SUCCESS:
            return {loading: false, userForumStat: action.payload};
        case GET_ALL_USERS_FORUM_STATS_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const botReducer = (state = {loading: true, stat: {}}, action) =>{
    switch(action.type){
        case GET_BOT_REQUEST: 
            return {loading: true};
        case GET_BOT_SUCCESS:
            return {loading: false, bot: action.payload};
        case GET_BOT_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const botForumStatReducer = (state = {loading: true, stat: {}}, action) =>{
    switch(action.type){
        case GET_ALL_BOT_FORUM_STATS_REQUEST: 
            return {loading: true};
        case GET_ALL_BOT_FORUM_STATS_SUCCESS:
            return {loading: false, botForumStat: action.payload};
        case GET_ALL_BOT_FORUM_STATS_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const filenamesReducer = (state = {loading: true, stat: {}}, action) =>{
    switch(action.type){
        case GET_FILENAMES_REQUEST: 
            return {loading: true};
        case GET_FILENAMES_SUCCESS:
            return {loading: false, filenames: action.payload};
        case GET_FILENAMES_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}