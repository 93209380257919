import React, { Fragment, useEffect } from 'react'
import {Link, useNavigate} from 'react-router-dom'
import MetaTags from '../components/MetaTags';
import DateComponent from '../components/DateComponent';
import UserMiniProfile from '../components/UserMiniProfile';

export default function HomePage(props) {
    const {user} = props;

    const navigate = useNavigate();
    const pfp = "https://cdn.discordapp.com/emojis/967412208323674243.webp?size=48&quality=lossless";


    useEffect(() => {
    }, [])
    const ogURL="%PUBLIC_URL%/mycoffeeicon2.png"

    const ogType="article"
        
    const ogTitle="Depresso Homepage"
        
    const ogDescription="Huỳnh Minh's Static DepressoV3 Homepage"

    const ogImage="%PUBLIC_URL%/mycoffeeicon2.png"



    return (
        <div style={{overflow: "auto"}}>
            <MetaTags url={ogURL} type={ogType} description={ogDescription} title={ogTitle} image={ogImage} ></MetaTags>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className='scrollAnimatedTextDiv'>
                <div className='row scrollInanimatedText right'><div>I'm</div> </div>
                <div>　</div>
                <div className='scrollAnimatedText row left'>
                    <span>
                        a CGI art hobbyist<br/>
                        a voice acting hobbyist<br/>
                        a full-stack devOps engineer<br/>
                        a history/language geek<br/>
                        a computer enthusiast<br/>
                        an overthinker<br/>
                        a nobody nevertheless. Up we go
                    </span>
                </div>
            </div>
            <div className='row center' style={{fontSize: "5rem"}}>
                and I am
            </div>
            
            {<div className='row center' style={{height: "20rem", position: "relative", overflow: "hidden"}} >
                <div className='col-2 ' >
                    <div className='row center'>
                        <div className='coffeeMachineTop'>
                            <div className='coffeeMachineButton'></div>
                            <div className='coffeeMachineButton2'></div>
                            <div className='coffeeMachinePipe'>
                                <div className='coffeeMachinePipe2'></div>
                            </div>
                        </div>
                    </div>
                    <div className='row center'>
                        <div className='coffeeMachineBody'>
                            <div className='coffeeMachineBottom'></div>
                        </div>
                        <div className='coffeeFluid'></div>
                        <div className='coffeeCup interactiveText' onClick={()=>navigate(`/@LhMinh2607`)}>
                            <div className='coffeeHandle'></div>
                        </div>
                        <div className='steam'></div>
                        <div className='steam2'></div>
                        <div className='steam3'></div>
                    </div>
                </div>
            </div>
            }
            <div className='row center' style={{paddingTop: "2rem"}}>CLICK ON THE COFFEE MUG ABOVE TO VIEW MY PROFILE</div>

            
            <div className='row center' style={{paddingTop: "5rem", height: "25rem", paddingBottom: "5rem"}}>
                <div className='col-2'>
                    <div className='row center'>
                        <div className='monitor'>
                        </div>
                    </div>
                    <div className='row center'>
                        <div className='monitorStand'>
                            <div className='monitorStand2'>

                            </div>
                        </div>  
                    </div>
                </div>
                
            </div>
            <div className='row center'>1 SCREEN FITS ALL</div>

            <div className='row center ' style={{padidngTop: "1rem", height: "30rem"}}>
                <div className='col-2'>
                    <div>
                        <div className='row center'>
                            <Link className='animatedChatBox interactiveText'  to={`https://github.com/LhMinh2607/depressoV3`}>
                                <div className='row left'>
                                    <img src={pfp} style={{height: "1.5rem"}}></img>MentalBot
                                </div>
                                <div className='animatedMessageContainer left'>
                                    <div className='animateMessage1 messageSent1'>Chào Minh</div>
                                </div>
                                <div  className='animatedMessageContainer left'>
                                    <div className='animateMessage1  messageSent2'>Khỏe hong?</div>
                                </div>
                                <div  className='animatedMessageContainer right'>
                                    <div className='animateMessage2 messageSent3'>hong</div>
                                </div>
                                <div  className='animatedMessageContainer'>
                                    <div className='animateMessage1  left messageSent4'>Sao nè?</div>
                                </div>
                                <div  className='animatedMessageContainer right'>
                                    <div className='animateMessage2  messageSent5'>mệt</div>
                                </div>
                            </Link>
                        </div>
                        <div className='row center'>

                        </div>
                    </div>
                </div>
            </div>
            <div className='row center'>LEGACY MENTALBOT</div>

            <div className='row center' style={{paddingTop: "5rem"}}>
                <div className='col-2'>
                    <div className='row center'>
                        <div className='phonePart'>
                            <div className='phonePart2'>
                            </div>
                            <div className='phonePart2 bottom'>
                            </div>
                        </div>
                        <div className='animatedPointer'>
                        </div>
                        <div className='animatedPointerHandle'>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div className='row center'>LEGACY MENTALBOT'S CALLCENTER</div>
            <br></br>
            <hr></hr>
            <br></br>
            <div className='row center'>
                <div className='row center'><div className='row'>THIS IS A STATIC PAGE CREATED BY</div>  
                    <UserMiniProfile user={user}></UserMiniProfile>
                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <hr></hr>
            <div className='row center'>200 most recent songs added to my codewithmusic playlist as of<Fragment>&nbsp;</Fragment>{<DateComponent passedDate={new Date()} isbirthDate={false}></DateComponent>}</div>
            <div className='row center'><iframe style={{position: "relative"}} src={`https://www.youtube.com/embed/videoseries?mute=0&loop=1&list=PLyTTovvxYVsO1UZajvQlvRQSrX61Q6tT3`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={true} width="500" height="500"></iframe></div>
            <div className='row center'>
            </div>
        </div>

    )
}
