import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams} from 'react-router-dom';
import DateComponent from '../components/DateComponent';
import 'react-datepicker/dist/react-datepicker.css'; 
import {isBrowser, isMobile} from 'react-device-detect';
import MetaTags from '../components/MetaTags';
import ImageList from '../components/ImageList';
import UserMiniProfile from '../components/UserMiniProfile';


export default function ProfilePage(props){

    const {user, userForumStat, bot, filenames} = props;
    console.log(user, userForumStat)
    

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [gender, setGender] = useState();
    const [birthDate, setbirthDate] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [occupation, setOccupation] = useState('');
    const [username, setUsername] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const [desc, setDesc] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [backgroundMusic, setBackgroundMusic] = useState('');
    const [avatar, setAvatar] = useState('');
    const [globalBackground, setGlobalBackground] = useState('');
    const [zoomin, setZoomin] = useState("")


    //uneditable
    const [mood, setMood] = useState('');
    const [issues, setIssues] = useState([]);
    

    var params = useParams();
    var userId = params.id;

    const navigate = useNavigate();

    const genders = [
        {_id: "Nam", name: "Nam"},
        {_id: "Nữ", name: "Nữ"},
        {_id: "Khác", name: "Khác"},
    ]

    const [currentTab, setCurrentTab] = useState('info');
    


    const [disabled, setDisabled] = useState(true); //const disabled = true, const setDisabled = () =>{};
    const [editButtonName, setButtonName] = useState(true);

    

    // const orderAggregate = useSelector(state => state.orderAggregate);
    // const {loading: loadingSpending, error: errorSpending, userSpending} = orderAggregate;

    const enableEdit = ()=>{
        
        setDisabled(!disabled);
        editButtonNameChange();
        
    }

    const editButtonNameChange = () =>{
        setButtonName(!editButtonName);
    }

    const loadConversationHistory = () =>{
        setOpenPopup(true);
    }

    const closePopup = () =>{
        setOpenPopup(false);
    }

    const getYouTubeLinkId = (url) =>{
        var regExp = /^.*((youtu.be\/|youtube.com\/shorts\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }
    const bools = [
        {_id: true, name: "Có"},
        {_id: false, name: "Không"},
    ]
    const setTheBoolean = (selectedValues) =>{
        setGlobalBackground(selectedValues[0].value);
    }

   
    const url = window.location.pathname.split('/').pop();
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
        // setName(user.name);
        // setEmail(user.email);
        // setbirthDate(new Date(user.dob));
        // setGender(user.gender);
        // setPhoneNumber(user.phoneNumber);
        // setAddress(user.address);
        // setOccupation(user.occupation);
        // setMood(user.mood);
        // setIssues(user.issues[user.issues.length - 1]);
        // setUsername(user.username);
        // setDesc(user.desc);
        // setBackgroundImage(user.backgroundImage);
        // setBackgroundMusic(user.backgroundMusic);
        // setAvatar(user.avatar);
        // setGlobalBackground(user.setGlobalBackground);
    }, [user]);
    return (
        <div className='mainprofile' style={user && user.backgroundImage ? {backgroundImage: `url("${user.backgroundImage}")`, backgroundSize: "contain", backgroundPosition: "top center", backgroundRepeat: "no-repeat",} : 
        {background: "linear-gradient(#04374b, transparent), linear-gradient(to top, rgb(0, 128, 255), transparent), linear-gradient(to bottom, rgb(127, 194, 248), transparent);"}}>
            {/* {userInfo && userInfo.backgroundImage && <img className='userbackground' src={userInfo.backgroundImage}></img>} */}
            {user && <MetaTags title={`${user.username} - ${user.name} | Profile Page`} description={user.desc} image={user.avatar} video={user.backgroundMusic}></MetaTags>}
            
            <div className='userProfilePicture'>
                {/* {user.profileDeco && user.profileDeco.includes("sun") && <div className='avatarDeco'>
                    <i class="fa-solid fa-sun"></i>
                </div>} */}
                {user && user.avatar ? <div className='avatarCircle interactiveText' style={{background: `url("${user.avatar}")`, backgroundSize: "cover", backgroundPosition: "center center"}}>
                {/* {user && user.role!=="bot" && <div className='avatarCheckmark left'>{userForumStat && userForumStat.userScore}<i className='fa fa-thumbs-up'></i></div>} */}
                {user && user.role==="admin" && <div className='avatarHat'><i class="fa-solid fa-crown creatorIcon"></i></div>}
                {user && user.role==="admin" && <div className='avatarCheckmark'><i className='fa-solid fa-user-astronaut'></i></div>}
                {user && user.role==="bot" && <div className='avatarCheckmark'><i class="fa-solid fa-robot"></i></div>}
                <div className='checkmarkHoverInfo'>
                    {user && (user.role==="admin" ? <div><i class="fa-solid fa-user-astronaut"></i>Creator, Administrator and Moderator</div> : user.role==="bot" && <div>Bot</div>)}    
                </div>
                <div className='creatorMark'>
                    {user && (user.role==="admin" && <div><i class="fa-solid fa-crown"></i>Creator</div>)}    
                </div>
                </div> : 
                <div className='avatarCircle interactiveText'>
                    {user && user?.username?.at(0)}
                </div>}
                
                
                {/* {user && user.role=="admin" && <div className='avatarCheckmark.offline'><i className='fa fa-check'></i></div>} */}
                
                {/* {userInfo && user && userInfo._id!== user._id && userInfo.friends && userInfo.friends.length>=0 && userInfo.friends.map((friend)=>(
                    friend.friendId!==user._id ? <div className='addFriendButton interactiveText' onClick={()=>addFriend(user._id)}>Thêm bạn</div> : friend.friendId===user._id && <div className='addFriendButton'>Bạn bè</div>
                )) } */}
                {/* {userInfo && user && userInfo._id!== user._id && !userInfo.friends && <div className='addFriendButton interactiveText' onClick={()=>addFriend(user._id)}>Thêm bạn</div>} */}
            </div>
            <div className="row center">
                {/* <label className="bold-text">Tên: 　</label>  */}
                <label className='avatarName'>{user && user.name}</label>    
            </div>
            <div className='row center'>
                <label className='avatarUsername'>({user &&`@${user.username}`})</label>
            </div>
            <div className='row center'>
                {user && <div className='quote'>"{user.desc}"</div>}
            </div>
            {isBrowser && user && user.backgroundMusic && <div className='row center'>
                <iframe src={`https://www.youtube.com/embed/${getYouTubeLinkId(user.backgroundMusic)}?autoplay=1&mute=0&loop=1&playlist=${getYouTubeLinkId(user.backgroundMusic)}`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={false} width="500" height="100" allow='autoplay'></iframe>
            </div>}
            <div className='row center'>
                <div id="navTab" className='navTab'>
                    <div className='tabItem interactiveText noHighlight' onClick={()=>setCurrentTab('info')}>
                        Info
                        {currentTab==='info' ? <div className='tabLine'></div> : <div className='emptyLine'></div>}
                    </div>

                    <div className='tabItem interactiveText noHighlight' onClick={()=>setCurrentTab('customization')}>
                        Personalization
                        {currentTab==='customization' ? <div className='tabLine'></div> : <div className='emptyLine'></div>}
                    </div>

                    <div className='tabItem interactiveText noHighlight' onClick={()=>setCurrentTab('stat')}>
                        Statistics
                        {currentTab==='stat' ? <div className='tabLine'></div> : <div className='emptyLine'></div>}
                    </div>

                    <div className='tabItem interactiveText noHighlight' onClick={()=>setCurrentTab('gallery')}>
                        Image gallery
                        {currentTab==='gallery' ? <div className='tabLine'></div> : <div className='emptyLine'></div>}
                    </div>

                    <div className='tabItem interactiveText noHighlight' onClick={()=>setCurrentTab('videos')}>
                        Video gallery
                        {currentTab==='videos' ? <div className='tabLine'></div> : <div className='emptyLine'></div>}
                    </div>
                </div>
            </div>
            
            {/* {userCon && userCon[0].events[0]} */}
            
            {/* <div id={`rasa-chat-widget`} data-root-element-id={`mainprofile`} data-websocket-url={`http://localhost:5005`} data-width={`1000`} data-avatar-url={`https://cdn.discordapp.com/emojis/967412208323674243.webp`}></div>
            <script src={`https://unpkg.com/@rasahq/rasa-chat`} type={`application/javascript`}></script> */}
            {/* <div className="row center orange-background"> 
                
                {
                    userInfo.role==='admin' ? (
                        <div className="divHoldingLinkButton" title="Whatever your customers ordered appeared here">
                            <Link to="/admin/order_list"  className="admin linkButton">List of Orders</Link>
                        </div>
                    ) : (
                        <div title="Whatever you ordered">
                            <Link to="/profile/order_history" className="linkButton">Order History</Link>
                        </div>
                    )
                }
                {
                    userInfo.role==='user' ? (
                        <div className="divHoldingLinkButton" title="Your citizens list">
                            <Link to="/admin/userList" className="admin linkButton">List of Users</Link>
                        </div>
                    ) : (
                        <div title="Your collections of assets">
                            <Link to="/profile/library" className="linkButton">Library</Link>
                        </div>
                    )
                }
                {
                    userInfo.role==='admin' && (
                        <div className="divHoldingLinkButton" title="Add more product">
                            <Link to="/admin/product/add_product" className="admin linkButton">Add a product<i className="fa fa-plus"></i></Link>
                        </div>)
                }
                {
                    userInfo.role==='admin' && (
                        <div className="divHoldingLinkButton" title="Not Death Note">
                            <Link to="/admin/dev_note" className="admin linkButton">Dev Note<i className="fa fa-book"></i></Link>
                        </div>)
                }
            </div>*/}
            { currentTab==="info" ? <form className={disabled ? "form scrollableDiv full" : "inputForm"}>
            
                
                {
                    <div>
                        
                            <div>
                                <div className='row'>
                                    <div className='col-0'>
                                        <div className="row left">
                                            <i className='fa fa-envelope'></i><label className="bold-text">Email: 　</label> {user && user.email}
                                        </div>
                                        <div className="row left">
                                            <i className='fa fa-phone'></i><label className="bold-text">Phone number: 　</label> {user && user.phoneNumber}
                                        </div>
                                        {user && user.role==="bot" && <div className="row left">
                                            <i className='fa fa-home'></i><label className="bold-text">Address: 　</label> {user && user.address}
                                        </div>}
                                    </div>
                                    <div className='col-0'>
                                        <div className="row left">
                                            {gender==="Nam" ? <i className='fa fa-mars'></i>
                                        : gender === "Nữ" ? <i className='fa fa-venus'></i> :
                                        gender === "Khác" && <i className='fa fa-intersex'></i>
                                        }<label className="bold-text">Gender: 　</label> {user && user.gender}
                                        </div>
                                        <div className="row left">
                                            <i className='fa fa-birthday-cake'></i><label className="bold-text">D.O.B: 　</label> {user && <DateComponent isbirthDate = "yes" passedDate = {user.dob}></DateComponent>}
                                        </div>
                                        {user && user.role==="bot" && <div className="row left">
                                            <i className='fa fa-tasks'></i><label className="bold-text">Occupation: 　</label> {user && user.occupation}
                                        </div>}
                                    </div>
                                </div>
                                
                                
                                
                                {user && user.role!=="bot" && <>
                                <hr></hr>
                                <div className="row center">
                                    <label className="bold-text">Mentalbot's information about you: </label> 
                                </div>
                                
                                {/* <div className='row left'>
                                    <label className='bold-text'>Thư viện hình: 　</label> <label onClick={()=>setCurrentTab("gallery")} className='interactiveText'>xem</label>
                                </div> */}
                                <div className="row left">
                                    <label className="bold-text">Mood: 　</label> {user.mood}
                                </div>
                                <div className="row left">
                                    <label className="bold-text">Issues: 　</label> <div style={{wordBreak: "break-all"}}>{user.issues}</div>
                                </div>
                                <div className='row left'>
                                    <label className='bold-text'>Conversation history: 　</label> {user && (user._id === userId || user.role==="admin") &&<div className='row center'>
                                        <div className='interactiveText' onClick={loadConversationHistory}> view (disabled)</div>
                                    </div>}
                                </div>
                                </>}
                                
                                {/* {user && user.role!=="bot" && <div className='row'>
                                <hr></hr>
                                    <div className='col-0'>
                                        <div className="row left">
                                            <i className='fab fa-instagram'></i><label className="bold-text">　</label> <Link to={user.instagramURL}>{user.instagram}</Link>
                                        </div>
                                        <div className="row left">
                                            <div className="row left"><i className='fab fa-discord'></i></div><label className="bold-text">　</label> <Link to={user.discordURL}>{user.discord}</Link>
                                        </div>
                                        <div className="row left">
                                        <div className="row left"><i className='fab fa-youtube'></i></div><label className="bold-text">　</label> <Link to={user.youtubeURL}>{user.youtube}</Link>
                                        </div>
                                    </div>
                                </div>} */}
                                <hr></hr>
                                <div>
                                    {/* {
                                        date
                                    } */}
                                </div>
                            </div>
                            
                        
                       
                    </div>
                }
            </form> : currentTab === "stat" ? 
            <form>
                {
                <div>
                    <div className='row center'>
                        {user && user.role!=="bot" ? <div className='col-0'>
                            <div className='row left'>
                                <i className='fa fa-calendar'></i><label className='bold-text'>Score: 　</label> {userForumStat && userForumStat.userScore}
                            </div>
                            <div className='row left'>
                                <i className='fa fa-calendar'></i><label className='bold-text'>Joining date: 　</label> {user && <DateComponent passedDate={user.createdAt} isbirthDate={false}></DateComponent>}
                            </div>
                            <div className="row left">
                                <i className='fa fa-comment'></i><label className="bold-text">Has sent 　 </label> {userForumStat && userForumStat.msgStat.userMsgCount} 　 messages
                            </div>
                            <div className="row left">
                                <i className='fa fa-comments'></i><label className="bold-text">Has 　 </label> {userForumStat && userForumStat.msgStat.botMsgCount} 　messages from　
                                
                                <div className='row center'>
                                    <UserMiniProfile user={bot} config="toRight"></UserMiniProfile>
                                </div>
                            </div>
                            {/* <div className="row left">
                                <i className='fa fa-home'></i><label className="bold-text">Lorem ipsum: 　</label> {address}
                            </div> */}
                        </div>
                        : <div className='row center'>
                            <div className='col-2'>
                                <div className='row left'>
                                    <i className='fa fa-date'></i><label className='bold-text'>Joining date: 　</label> {user && <DateComponent passedDate={user.createdAt} isbirthDate={false}></DateComponent>}
                                </div>
                            </div>
                        </div>    
                    
                    }
                        <hr></hr>
                    </div>
                    {/* {userForumStat && <div>Bài viết ({userForumStat.posts.length})</div>}
                    <div className='col-1'>
                        {userForumStat && userForumStat.posts && userForumStat.posts.map((post)=>(
                                <div className='row'>
                                    <div className='interactiveText' onClick={()=>navigate(`/forum/post/${post._id}`)}>
                                        <i className='fa fa-arrow-right'></i> {post.title}
                                    </div>
                                </div>
                            ))}
                    </div> */}
                </div>}
            </form>
            : currentTab==='customization' ?
            <form><div>
                                {/* <div className='row'>
                                    <div className='col-1'>
                                    </div>
                                    <div className='col-1'>
                                    </div>
                                </div> */}
                                <div className='row left'>
                                    <div className='col-1'>
                                        <div className='row top'>Introduction:</div>
                                    </div>
                                    <div className='col-1'>
                                        {user && <div className='quote'>"{user.desc}"</div>}
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='row left'>
                                    <div className='col-1'>
                                        <div className='row top'>Profile picture:</div>
                                    </div>
                                    <div className='col-1'>
                                        {user && user.avatar && <img onClick={()=>setZoomin(user.avatar)} className='displayImage' src={user.avatar}></img>}
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='row left'>
                                    <div className='col-1'>
                                        <div className='row top'>Background:</div>
                                    </div>
                                    <div className='col-1'>
                                        {user && user.backgroundImage && <img onClick={()=>setZoomin(user.backgroundImage)} className='displayImage' src={user.backgroundImage}></img>}
                                        
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='row left'>
                                    <div className='col-1'>
                                        <div className='row top'>Background music:</div>
                                    </div>
                                    <div className='col-1'>
                                        {isBrowser && user && user.backgroundMusic &&
                                        <iframe src={`https://www.youtube.com/embed/${getYouTubeLinkId(user.backgroundMusic)}?mute=0&loop=1`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={false} width="300" height="100" allow='autoplay'></iframe>
                                        }
                                        {isMobile && <div><Link className='interactiveText' to={user.backgroundMusic}>Tap to view this user's song on YouTube</Link> (Listening to the song directly is only supported on desktop browsers)</div>}
                                        <br></br>
                                        <br></br>
                                    </div>
                                </div>
                                
                            </div></form>
            :
            currentTab === "gallery" ?
            <div className=''>
                {user._id === "6258e2b0ee1e676f8626d4bd" ? <ImageList filenames={filenames}></ImageList> : 
                
                
                <form><div>
                    <div className='row left'>
                        <div className='col-1'>
                            <div className='row top'>This user has no images</div>
                        </div>
                    </div>
                    
                </div></form>
                }
            </div> :
            currentTab === "videos" &&
            <div className='scrollableDiv row center'>
                {user?.videos ? user?.videos.length > 0 && user.videos.map((video)=>
                    <div className='col-0'>
                        <iframe src={`https://www.youtube.com/embed/${getYouTubeLinkId(video)}?mute=0&loop=1&playlist=${getYouTubeLinkId(video)}`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={false} width="500" height="500"></iframe>
                    </div>   
                ) : <form><div>
                        <div className='row left'>
                            <div className='col-1'>
                                <div className='row top'>This user has no videos</div>
                            </div>
                        </div>
                    </div></form>}
            </div>
            }
           {zoomin && <div className='viewingImageDiv' onClick={()=>setZoomin("")}><img className='zoomimg' src={`${zoomin}`}></img> <div className='' style={{backgroundColor: "#000"}}>click again to close</div></div>}
           {isBrowser && user && <div className='titleCardContainer'><div className='titleCard'>{`@${user.username}`}</div></div>}
        </div>
        
    )
}