// MetaTags.js
import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = (props) => {
  const {title, description, image, video, url, type} = props
  const ogTitle="Depresso";
  const ogDescription="Huỳnh Minh's Static DepressoV3";
  const ogImage="%PUBLIC_URL%/mycoffeeicon2.png";
  const ogVideo="";
  const ogUrl="%PUBLIC_URL%/mycoffeeicon2.png";
  const ogType="article";

  return (
    <Helmet>
      <meta property="og:title" content={title ? title : ogTitle} />
      <meta property="og:description" content={description ? description : ogDescription} />
      <meta property="og:image" content={image ? image : ogImage} />
      <meta property="og:video" content={video ? video : ogVideo} />
      <meta property="og:url" content={url ? url : ogUrl} />
      <meta property="og:type" content={type ? type : ogType} />
    </Helmet>
  );
};

export default MetaTags;
