import React from 'react'
import {Link} from 'react-router-dom';


export default function NotFoundPage() {
    return (
        <div>
            
            <div className="row center">
                <div title='404' className='glitch-div' style={{fontSize: '10rem'}}>
                    404
                </div>
            </div>
            <div className="row center">
                 <Link to='/'>Back to Homepage</Link>   
            </div>
        </div>
    )
}
