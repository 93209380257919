import React from 'react'

export default function YouTubeVideoPlayer(props) {
    const {height, width, mute, loop, allowFullScreen, videoId, autoplay} = props
    const getYouTubeLinkId = (url) =>{
        if(url){
          var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
          var match = url.match(regExp);
          return (match&&match[7].length==11)? match[7] : false;
        }else{
          return ""
        }
    }
  return (
    <div>
        <iframe src={`https://www.youtube.com/embed/${getYouTubeLinkId(videoId)}?mute=${mute ? 1 : 0}&loop=${loop ? 1 : 0}&autoplay=${autoplay ? 1 : 0}&playlist=${getYouTubeLinkId(videoId)}`} type="application/x-shockwave-flash" allowscriptaccess="always" allowFullScreen={allowFullScreen} width={width} height={height}></iframe>
    </div>
  )
}
