import React, { Fragment } from 'react'
import DateComponent from './DateComponent';
import { useNavigate } from 'react-router';

export default function UserMiniProfile(props) {

    const {user, config, placebotext} = props;
    const navigate = useNavigate()

  return (
    <>{user && user.name && <div className='interactiveText' onClick={()=>{navigate(`/@${user.username}`);window.location.reload()}}>
        <div className='interactiveUsername row' style={{height: "15rem", position: "relative"}}>
            {placebotext && <span className='row center'>{placebotext}<Fragment>&nbsp;</Fragment></span>}
            {user.avatar ? <span className='avatarSquare' style={{background: `url("${user.avatar}")`, backgroundSize: "contain", backgroundPosition: "center center"}}></span> : <span className='avatarSquare interactiveUsername'>{user.username[0]}</span>}
            <div className={`userHoverInfo ${config ? config : ""}`} style={user ? user.backgroundImage ? {background: `url("${user.backgroundImage}")`, backgroundSize: 'cover'} : {backgroundColor: "#04374b"} : {backgroundColor: "#04374b"}}>
                <h1>{user.role==="user"&&<i className='fa fa-user'></i>}{user.username}{user.role==="admin"&&<i className='fa fa-check'></i>}</h1>
                <div className={`row center userHoverInfoContent`}>
                    <div className=''>
                        <div className='row left'>{user.name}</div>
                    </div>
                    <div className='col-2'>
                        {user.gender==="Nam" ? <div className='row left'><i className='fa fa-mars'/>{user.gender}</div> : 
                            user.gender==="Nữ" ? <div className='row left'><i className='fa fa-venus'/>{user.gender}</div> :
                            user.gender==="Khác" && <div className='row left'><i className='fa fa-intersex'/>{user.gender}</div>
                        }
                        <div className='row left'><i className='fas fa-brain'></i>{user.mood ? user.mood : "Unknown mood"}</div>
                        <div className='row left'><i className='fa fa-birthday-cake'></i>{<DateComponent passedDate={user.dob} isbirthDate={true}></DateComponent>} </div>
                    </div>
                </div>
            </div>
            <b>@{user.username}</b>
        </div>
    </div>}</>
  )
}
