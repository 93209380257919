import axios from 'axios';
import { GET_ALL_BOT_FORUM_STATS_FAIL, GET_ALL_BOT_FORUM_STATS_REQUEST, GET_ALL_BOT_FORUM_STATS_SUCCESS, GET_ALL_DATA_FAIL, GET_ALL_DATA_REQUEST, GET_ALL_DATA_SUCCESS, GET_ALL_USER_FORUM_STATS_REQUEST, GET_ALL_USERS_FAIL, GET_ALL_USERS_FORUM_STATS_FAIL, GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_BOT_FAIL, GET_BOT_REQUEST, GET_BOT_SUCCESS, GET_FILENAMES_FAIL, GET_FILENAMES_REQUEST, GET_FILENAMES_SUCCESS } from '../constants/allData';

export const getUsers = () => async (dispatch) =>{
    dispatch({
        type: GET_ALL_USERS_REQUEST
    });
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_STATIC_CDN}/json/user.json`);
        dispatch({type: GET_ALL_USERS_SUCCESS, payload: data});
        localStorage.setItem('user', JSON.stringify(data))
    } catch (error) {
        dispatch({type: GET_ALL_USERS_FAIL, 
            payload: error.response 
            && error.response.data.message 
            ? error.response.data.message
            : error.message,});
    }
};

export const getUserForumStat = () => async (dispatch) =>{
    dispatch({
        type: GET_ALL_USER_FORUM_STATS_REQUEST
    });
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_STATIC_CDN}/json/userForumStat.json`);
        dispatch({type: GET_ALL_BOT_FORUM_STATS_SUCCESS, payload: data});
        localStorage.setItem('userForumStat', JSON.stringify(data))
    } catch (error) {
        dispatch({type: GET_ALL_USERS_FORUM_STATS_FAIL, 
            payload: error.response 
            && error.response.data.message 
            ? error.response.data.message
            : error.message,});
    }
};

export const getBot = () => async (dispatch) =>{
    dispatch({
        type: GET_BOT_REQUEST
    });
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_STATIC_CDN}/json/bot.json`);
        dispatch({type: GET_BOT_SUCCESS, payload: data});
        localStorage.setItem('bot', JSON.stringify(data))
    } catch (error) {
        dispatch({type: GET_BOT_FAIL, 
            payload: error.response 
            && error.response.data.message 
            ? error.response.data.message
            : error.message,});
    }
};

export const getBotForumStat = () => async (dispatch) =>{
    dispatch({
        type: GET_ALL_BOT_FORUM_STATS_REQUEST
    });
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_STATIC_CDN}/json/botForumStat.json`);
        dispatch({type: GET_ALL_BOT_FORUM_STATS_SUCCESS, payload: data});
        localStorage.setItem('botForumStat', JSON.stringify(data))
    } catch (error) {
        dispatch({type: GET_ALL_BOT_FORUM_STATS_FAIL, 
            payload: error.response 
            && error.response.data.message 
            ? error.response.data.message
            : error.message,});
    }
};

export const getFilenames = () => async (dispatch) =>{
    dispatch({
        type: GET_FILENAMES_REQUEST
    });
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_STATIC_CDN}/json/filenames.json`);
        dispatch({type: GET_FILENAMES_SUCCESS, payload: data});
        localStorage.setItem('filenames', JSON.stringify(data))
    } catch (error) {
        dispatch({type: GET_FILENAMES_FAIL, 
            payload: error.response 
            && error.response.data.message 
            ? error.response.data.message
            : error.message,});
    }
};